<template>
  <div class="">
    <!-- Modal pour ajouter une nouvelle taxe -->
    <b-modal
      id="modal-projet-created"
      cancel-variant="outline-secondary"
      ok-title="Créer"
      cancel-title="Annuler"
      centered
      title="Nouveau projet"
      @ok="handleOk"
    >
      <b-form>
        <div>
          <h5 class="text-danger text-center">{{ exist_text }}</h5>
        </div>
        <!-- libelle -->
        <b-form-group label-for="register-nom">
          <validation-provider
            #default="{ errors }"
            name="libelle"
            rules="required"
          >
            <label for=""
              >Libellé <span class="p-0 text-danger h6">*</span></label
            >
            <b-form-input
              id="register-nom"
              @input="validateLibelle"
              v-model="libelle"
              name="register-nom"
              :state="errors.length > 0 ? false : null"
              placeholder="libelle du projet"
            />
            <small
              :class="valideLibelle ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner le libellé du projet
            </small>
          </validation-provider>
        </b-form-group>

        <!-- Budget -->
        <b-form-group label-for="register-nom">
          <validation-provider
            #default="{ errors }"
            name="budget"
            rules="required"
          >
            <label for=""
              >Budget <span class="p-0 text-danger h6">*</span></label
            >
            <b-form-input
              id="register-nom"
              @input="validateBudget"
              v-model="budget"
              name="register-nom"
              :state="errors.length > 0 ? false : null"
              placeholder="Budget alloué au projet"
            />
            <small :class="valideBudget ? 'block' : 'none'" class="text-danger">
              Vous devez renseigner le budget alloué au projet
            </small>
            <small :class="budgetGood ? 'block' : 'none'" class="text-danger">
              Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
              virgule.<br />
              Un POINT( . ) à la place de la virgule( , )
            </small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <label for=""
            >Date de début <span class="p-0 text-danger h6">*</span></label
          >
          <validation-provider>
            <flat-pickr v-model="dateDebut" class="form-control" />
            <small
              :class="valideDateDebut ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner la date de début du projet
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for=""
            >Date de fin <span class="p-0 text-danger h6">*</span></label
          >
          <validation-provider>
            <flat-pickr
              v-model="dateFin"
              class="form-control"
              @input="validateDate"
            />
            <small
              :class="valideDateFin ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner la date de fin du projet
            </small>
            <small
              :class="datePasCorrecte ? 'block' : 'none'"
              class="text-danger"
            >
              La date de début doit être inférieure à la date de fin
            </small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <label for="taxeValue">Description</label>
          <b-form-textarea
            id="textarea"
            v-model="description"
            placeholder="Entrer les details du projet ici"
            rows="5"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          :disabled="loading === true ? true : false"
          variant="primary"
          @click.stop.prevent="handleOk"
        >
          <span v-if="loading === false">Ajouter</span>
          <b-spinner v-if="loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <!-- Modal pour mettre à jour une taxe déjà ajouté -->
    <b-modal
      id="modal-projet-updated"
      cancel-variant="outline-secondary"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      centered
      title="Modifier la taxe"
      @ok="updateProjet"
    >
      <b-form>
        <!-- libelle -->
        <b-form-group label-for="register-nom">
          <validation-provider
            #default="{ errors }"
            name="libelle"
            rules="required"
          >
            <label for=""
              >Libellé <span class="p-0 text-danger h6">*</span></label
            >
            <b-form-input
              id="register-nom"
              @input="validateEditLibelle"
              v-model="edit_libelle"
              name="register-nom"
              :state="errors.length > 0 ? false : null"
              placeholder="libelle du projet"
            />
            <small
              :class="valideEditLibelle ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner le libellé du projet
            </small>
          </validation-provider>
        </b-form-group>

        <!-- Budget -->
        <b-form-group label-for="register-nom">
          <validation-provider
            #default="{ errors }"
            name="budget"
            rules="required"
          >
            <label for=""
              >Budget <span class="p-0 text-danger h6">*</span></label
            >
            <b-form-input
              id="register-nom"
              @input="validateEditBudget"
              v-model="edit_budget"
              name="register-nom"
              :state="errors.length > 0 ? false : null"
              placeholder="Budget alloué au projet"
            />
            <small
              :class="valideEditBudget ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner le budget alloué au projet
            </small>
            <small
              :class="budgetEditGood ? 'block' : 'none'"
              class="text-danger"
            >
              Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
              virgule.<br />
              Un POINT( . ) à la place de la virgule( , )
            </small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <label for=""
            >Date de début <span class="p-0 text-danger h6">*</span></label
          >
          <validation-provider>
            <flat-pickr
              v-model="edit_dateDebut"
              class="form-control"
              @input="validateEditDateDebut"
            />
            <small
              :class="valideEditDateDebut ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner la date de début du projet
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for=""
            >Date de fin <span class="p-0 text-danger h6">*</span></label
          >
          <validation-provider>
            <flat-pickr
              v-model="edit_dateFin"
              class="form-control"
              @input="validateEditDate"
            />
            <small
              :class="valideEditDateFin ? 'block' : 'none'"
              class="text-danger"
            >
              Vous devez renseigner la date de fin du projet
            </small>
            <small
              :class="dateEditPasCorrecte ? 'block' : 'none'"
              class="text-danger"
            >
              La date de doit être inférieure à la date de fin
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="taxeValue">Description</label>
          <b-form-textarea
            id="textarea"
            v-model="edit_description"
            placeholder="Entrer les details du projet ici"
            rows="5"
            max-rows="6"
          >
          </b-form-textarea>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          :disabled="loading === true ? true : false"
          variant="primary"
          @click.stop.prevent="updateProjet"
        >
          <span v-if="loading === false">Ajouter</span>
          <b-spinner v-if="loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <b-card no-body class="">
      <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->

      <b-row class="px-2">
        <!-- Per Page -->

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1 "
        >
          <label>Entrées</label>

          <v-select
            v-model="state.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <b-button variant="primary" v-b-modal.modal-projet-created>
            <feather-icon icon="PlusIcon" class="mx-auto" />
            Nouveau projet
          </b-button>
        </b-col>

        <!-- Search -->

        <b-col cols="12" md="6" class="mt-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="state.filtre"
                class="d-inline-block mr-1"
                placeholder="Rechercher par : nom du projet, budget, date d'ajout..."
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>

      <q-loader-table
        :success="state.success"
        :empty="state.empty"
        :warring="state.warring"
      />

      <b-table
        hover
        responsive
        primary-key="id"
        :per-page="state.perPage"
        :current-page="state.currentPage"
        :items="projets"
        :fields="tableColumns"
        :filter="state.filter"
        show-empty
        empty-text="Aucun projet enregistré"
        class="bg-white  text-left"
        v-if="state.success === true"
      >
        <template #cell(libelle)="data">
          <div class="d-flex flex-column">
            <span>{{ data.item.libelle }}</span>
          </div>
        </template>

        <template #cell(budget)="data">
          <span>{{ formatter(parseInt(data.item.budget)) }}</span>
        </template>

        <template #cell(description)="data">
          <div class="my-50">
            <span :id="`invoice-row-${data.item.id}-describle`">{{
              data.item.description | toSubtr
            }}</span>
            <b-tooltip
              :title="data.item.description"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-describle`"
            />
          </div>
        </template>

        <!-- Bouton d'action -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap py-1">
            <feather-icon
              @click="update(data.item.id)"
              v-b-modal.modal-projet-updated
              icon="Edit3Icon"
              size="16"
              class="cursor-pointer"
            />

            <feather-icon
              @click="confirmText(data.item.id)"
              icon="TrashIcon"
              class="ml-1 cursor-pointer"
              size="16"
            />

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
              disabled
              class="hidden"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
              <b-dropdown-item disabled>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50"> Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <!-- Les boutons de pagination -->

      <div class="mx-2 my-2 pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>

          <!-- Pagination -->

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="state.currentPage"
              :total-rows="projets.length"
              :per-page="state.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    {{ __NEXT }}
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import { reactive, ref, computed, onMounted } from '@vue/composition-api';
import { qDecrypted } from '@/services/qeCrypt';
import qDeviseUtils from '@/utils/qDeviseUtils';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import moment from 'moment';
import { toast_destory, toast_error, toast_sucess, toast_update } from '@/utils/qToast';

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BLink,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    required,
    email,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    axios,
    QLoaderTable,
  },
  directives: {
    Ripple,
  },
  filters: {
    toSubtr(value) {
      if (!value) return '';
      value = value.toString();
      if (value.length > 15) {
        return value.substring(0, 15) + '...';
      } else {
        return value;
      }
    },
    toUpper(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      datePasCorrecte: false,
      dateEditPasCorrecte: false,
      description: '',
      edit_description: '',
      valideLibelle: false,
      libelle: '',
      valideEditLibelle: false,
      edit_libelle: '',
      valideBudget: false,
      budget: '',
      budgetGood: false,
      valideEditBudget: false,
      edit_budget: '',
      budgetEditGood: false,
      date: null,
      dateDebut: null,
      valideDateDebut: false,
      valideDateFin: false,
      dateFin: null,
      edit_dateDebut: null,
      valideEditDateDebut: false,
      edit_dateFin: null,
      valideEditDateFin: false,
      projetUp: '',
      indexUp: '',
      valideExist: false,
      exist_text: '',

      nom: '',
      valideNom: false,
      valeur: '',
      valideValeur: false,
      newTaxeName: '',
      newTaxeValue: '',
      taxes: [],
      updateIndex: '',
      valideEditValeur: false,
      valideEditNom: false,
      edit_nom: '',
      edit_valeur: '',
      taxeUp: '',

      valeurGood: false,
      valeurEditGood: false,
      loading: false
    };
  },
  setup() {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: '',
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const projets = ref([]);
    const tableColumns = reactive([
      {
        key: 'libelle',
        label: 'Libelle & categorie',
      },
      //   {
      //     key: 'type',
      //     label: 'Types',
      //   },
      // {
      //   key: 'prix_achat',
      //   label: 'prix de achat',
      // },
      {
        key: 'budget',
        label: 'budget',
      },
      {
        key: 'description',
        label: 'description',
      },
      {
        key: 'created_at',
        label: "date d'ajout",
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ]);
    const perPageOptions = [30, 50, 100, 150, 250, 500];
    const GIVE = ref(false);

    const __NEXT = computed(() => {
      if (GIVE.value === true) {
        projets.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
      return;
    });

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem('entreprise_info');
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };
    return {
      state,
      perPageOptions,
      tableColumns,
      __NEXT,
      projets,
      formatter,
      GIVE,
    };
  },
  async mounted() {
    document.title = 'Projets - Ediqia';
    try {
      await axios
        .get(URL.PROJET_LIST)
        .then((response) => {
          this.returnData = response;
          this.projets = this.returnData.data[1];
          this.projets.forEach((projet, index) => {
            projet.created_at = moment(projet.created_at).format('DD-MM-YYYY');
          });
          this.projets.reverse()
          this.GIVE = true;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
        });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    isDigit(str) {
      return /^\d+$/.test(str);
    },
    isFloat(str) {
      return /^[-+]?[0-9]+\.[0-9]+$/.test(str);
    },
    validateDateDebut() {
      if (!this.dateDebut) {
        this.valideDateDebut = true;
      }
    },
    validateDateFin() {
      if (!this.dateFin) {
        this.valideDateFin = true;
      }
    },
    validateDate() {
      if (this.dateDebut > this.dateFin) {
        this.datePasCorrecte = true;
      }
    },
    validateEditDate() {
      if (this.edit_dateDebut > this.edit_dateFin) {
        this.dateEditPasCorrecte = true;
      }
    },
    validateEditDateDebut() {
      if (!this.edit_dateDebut) {
        this.valideEditDateDebut = true;
      }
    },
    validateEditDateFin() {
      if (!this.edit_dateFin) {
        this.valideEditDateFin = true;
      }
    },
    validateLibelle() {
      if (!this.libelle) {
        this.valideLibelle = true;
      } else {
        this.valideLibelle = false;
      }
    },
    validateEditLibelle() {
      if (!this.edit_libelle) {
        this.valideEditLibelle = true;
      } else {
        this.valideEditLibelle = false;
      }
    },
    validateBudget() {
      if (!this.budget) {
        this.valideBudget = true;
      } else {
        this.valideBudget = false;
      }
      if (this.budget && this.isFloat(this.budget) === false) {
        if (this.isDigit(this.budget) === false) {
          this.budgetGood = true;
        } else {
          this.budgetGood = false;
        }
      } else {
        this.budgetGood = false;
      }
    },
    validateEditBudget() {
      if (!this.edit_budget) {
        this.valideEditBudget = true;
      } else {
        this.valideEditBudget = false;
      }
      if (this.edit_budget && this.isFloat(this.edit_budget) === false) {
        if (this.isDigit(this.edit_budget) === false) {
          this.budgetEditGood = true;
        } else {
          this.budgetEditGood = false;
        }
      } else {
        this.budgetEditGood = false;
      }
    },
    // confirm texrt
    confirmText(id, index) {
      this.$swal({
        title: 'Êtes vous sûr?',
        text: 'Cette taxe sera supprimée définitivement !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteProjet(id, index);
        }
      });
    },
    isDigit(str) {
      return /^\d+$/.test(str);
    },
    isFloat(str) {
      return /^[-+]?[0-9]+\.[0-9]+$/.test(str);
    },

  /*

  *
  *
  * *
  */

    async deleteProjet(indentifiant, index) {
      const id_delete = {
        id: indentifiant,
      };
      try {
        await axios
          .post(URL.PROJET_DESTROY, id_delete)
          .then((response) => {
            if (response.data){
              this.projets = this.projets.filter(projet => projet.id !== indentifiant);
              toast_destory(this, 'warning', 'top-right', 'Projet')
            }
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      } catch (error) {
        console.log(error.type);
      }
    },


    /*

  *
  *
  * *
  */

    update(index) {
      this.projetUp = this.projets.filter((projet) => {
        console.log(projet.id, index);
        return projet.id === index;
      });
      console.log(this.projetUp);
      this.edit_libelle = this.projetUp[0].libelle;
      this.edit_budget = this.projetUp[0].budget;
      this.edit_dateDebut = this.projetUp[0].date_debut;
      this.edit_dateFin = this.projetUp[0].date_fin;
      this.edit_description = this.projetUp[0].description;
      this.indexUp = index;
    },




    /*

  *
  *
  * *
  */

    async updateProjet() {
      this.validateEditDateDebut();
      this.validateEditDateFin();
      this.validateEditLibelle();
      this.validateEditBudget();
      try {
        this.validateDateDebut();
        this.validateDateFin();
        this.validateLibelle();
        this.validateBudget();
        this.if_exist();
        // Prevent modal from closing
        if (
          this.valideEditBudget ||
          this.valideEditDateFin ||
          this.valideEditDateDebut ||
          this.valideEditLibelle
        ) {
          bvModalEvt.preventDefault();
          this.handleSubmit();
        } else {
          this.loading = true
          const data = {
            id: this.indexUp,
            libelle: this.edit_libelle,
            date_debut: this.edit_dateDebut,
            date_fin: this.edit_dateFin,
            budget: this.edit_budget,
            description: this.edit_description,
          };
          await axios.post(URL.PROJET_UPDATE, data).then((response) => {
            if(response.data){
              this.projets.forEach((projet) => {
              if (projet.id === this.indexUp) {
                projet.libelle = this.edit_libelle;
                projet.budget = this.edit_budget;
                projet.date_debut = this.edit_dateDebut;
                projet.date_fin = this.edit_dateFin;
                projet.description = this.edit_description;
              }
            });



          this.loading = false
            this.$bvModal.hide('modal-projet-updated')
            toast_update(this, 'success', 'top-right', 'Projets')
     
            }
          });
        }
      } catch (error) {
         this.loading = false
        toast_error(this, 'dandger', 'top-right', 'Projets')
        console.log(error.type);
      }
    },
    validateNom() {
      // console.log(this.valideNom)
      if (!this.nom) {
        this.valideNom = true;
      } else {
        this.valideNom = false;
      }
    },
    validateValeur() {
      if (!this.valeur) {
        this.valideValeur = true;
      } else {
        this.valideValeur = false;
      }
      if (this.valeur && this.isFloat(this.valeur) === false) {
        if (this.isDigit(this.valeur) === false) {
          this.valeurGood = true;
        } else {
          this.valeurGood = false;
        }
      } else {
        this.valeurGood = false;
      }
    },
    validateEditNom() {
      if (!this.edit_nom) {
        this.valideEditNom = true;
      } else {
        this.valideEditNom = false;
      }
    },
    validateEditValeur() {
      if (!this.edit_valeur) {
        this.valideEditValeur = true;
      } else {
        this.valideEditValeur = false;
      }
      if (this.edit_valeur && this.isFloat(this.edit_valeur) === false) {
        if (this.isDigit(this.edit_valeur) === false) {
          this.valeurEditGood = true;
        } else {
          this.valeurEditGood = false;
        }
      } else {
        this.valeurEditGood = false;
      }
    },
    topEnd() {
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: 'Taxe enregistrée avec succès',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },
    if_exist() {
      for (let index = 0; index < this.taxes.length; index++) {
        if (this.nom == this.taxes[index].libelle) {
          this.valideExist = true;
          this.exist_text = 'Cette taxe existe déjà !';
          index = this.taxes.length;
        } else {
          this.valideExist = false;
        }
      }
    },


    /*

  *
  *
  * *
  */

    async handleOk(bvModalEvt) {
      try {
        this.validateDateDebut();
        this.validateDateFin();
        this.validateLibelle();
        this.validateBudget();
        this.if_exist();
        // Prevent modal from closing
        if (
          this.valideBudget ||
          this.valideDateFin ||
          this.valideDateDebut ||
          this.valideLibelle
        ) {
          bvModalEvt.preventDefault();
          this.handleSubmit();
        } else {
          this.loading = true
          const data = {
            libelle: this.libelle,
            date_debut: this.dateDebut,
            date_fin: this.dateFin,
            budget: this.budget,
            description: this.description,
          };
          await axios.post(URL.PROJET_CREATE, data).then((response) => {
            this.projetData = response.data[1];
            this.projetData.created_at = moment(this.projetData.created_at).format('DD-MM-YYYY')
            this.projets.unshift(this.projetData);
            this.libelle = '';
            this.budget = '';
            this.description = '';
            this.dateFin = '';
            this.dateDebut = '';
            this.loading = false
            this.$bvModal.hide('modal-projet-created')
            toast_sucess(this, 'success', 'top-right', 'Projets')
          });
        }
      } catch (error) {
        this.loading = false
        toast_error(this, 'dandger', 'top-right', 'Projets')
        console.log(error.type);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}
.none {
  display: none;
}
.block {
  display: inline-block;
}
</style>
